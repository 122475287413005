import React from 'react'
import { graphql, Link } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Layout from "../../components/Layout";
import Slider from "react-slick";
import postFeedback from "../../components/PostFeedback";
import SEO from "../../components/SEO";

class efiles extends React.Component {
    constructor(props) {
        super(props);
        this.increaseImageWidthRow1 = this.increaseImageWidthRow1.bind(this);
        this.increaseImageWidthRow2 = this.increaseImageWidthRow2.bind(this);
        this.increaseImageWidthRow3 = this.increaseImageWidthRow3.bind(this);
        this.increaseImageWidthRow4 = this.increaseImageWidthRow4.bind(this);
        this.increaseImageWidthRow5 = this.increaseImageWidthRow5.bind(this);
        this.hitButtonLiveDemoeFiles = this.hitButtonLiveDemoeFiles.bind(this);
        this.hitButtonPricingeFiles = this.hitButtonPricingeFiles.bind(this);
    }

    // componentDidMount() {
    //     const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    //     document.querySelector(".app-section-efiles-try-product-free .app-efiles-try-product-free-detail .app-efiles-try-product-free-faqs-onlinedoc a.app-efiles-try-product-free-faqs-onlinedoc-a-tag").setAttribute("href", new_online_doc_url + "odoc/07-00-00.html");
    // }

	increaseImageWidthRow1() {
        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-left').classList.toggle('app-efiles-row-1-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-right').classList.toggle('app-efiles-row-1-col-right-active');

        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-left').classList.remove('app-efiles-row-2-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-right').classList.remove('app-efiles-row-2-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-left').classList.remove('app-efiles-row-3-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-right').classList.remove('app-efiles-row-3-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-left').classList.remove('app-efiles-row-4-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-right').classList.remove('app-efiles-row-4-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-left').classList.remove('app-efiles-row-5-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-right').classList.remove('app-efiles-row-5-col-right-active');
    }

    increaseImageWidthRow2() {
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-left').classList.toggle('app-efiles-row-2-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-right').classList.toggle('app-efiles-row-2-col-right-active');

        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-left').classList.remove('app-efiles-row-1-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-right').classList.remove('app-efiles-row-1-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-left').classList.remove('app-efiles-row-3-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-right').classList.remove('app-efiles-row-3-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-left').classList.remove('app-efiles-row-4-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-right').classList.remove('app-efiles-row-4-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-left').classList.remove('app-efiles-row-5-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-right').classList.remove('app-efiles-row-5-col-right-active');
    }

    increaseImageWidthRow3() {
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-left').classList.toggle('app-efiles-row-3-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-right').classList.toggle('app-efiles-row-3-col-right-active');

        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-left').classList.remove('app-efiles-row-1-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-right').classList.remove('app-efiles-row-1-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-left').classList.remove('app-efiles-row-2-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-right').classList.remove('app-efiles-row-2-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-left').classList.remove('app-efiles-row-4-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-right').classList.remove('app-efiles-row-4-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-left').classList.remove('app-efiles-row-5-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-right').classList.remove('app-efiles-row-5-col-right-active');
    }

    increaseImageWidthRow4() {
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-left').classList.toggle('app-efiles-row-4-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-right').classList.toggle('app-efiles-row-4-col-right-active');

        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-left').classList.remove('app-efiles-row-1-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-right').classList.remove('app-efiles-row-1-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-left').classList.remove('app-efiles-row-2-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-right').classList.remove('app-efiles-row-2-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-left').classList.remove('app-efiles-row-3-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-right').classList.remove('app-efiles-row-3-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-left').classList.remove('app-efiles-row-5-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-right').classList.remove('app-efiles-row-5-col-right-active');
    }

    increaseImageWidthRow5() {
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-left').classList.toggle('app-efiles-row-5-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-5 .app-efiles-row-5-col-right').classList.toggle('app-efiles-row-5-col-right-active');

        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-left').classList.remove('app-efiles-row-1-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-1 .app-efiles-row-1-col-right').classList.remove('app-efiles-row-1-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-left').classList.remove('app-efiles-row-2-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-2 .app-efiles-row-2-col-right').classList.remove('app-efiles-row-2-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-left').classList.remove('app-efiles-row-3-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-3 .app-efiles-row-3-col-right').classList.remove('app-efiles-row-3-col-right-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-left').classList.remove('app-efiles-row-4-col-left-active');
        document.querySelector('.app-section-efiles .app-efiles-row-4 .app-efiles-row-4-col-right').classList.remove('app-efiles-row-4-col-right-active');
    }

    hitButtonLiveDemoeFiles() {
        var data = {};
        data["type"] = "click";
        data["button"] = "liveDemoeFiles";
        
        var checkItemLocalStorage = localStorage.getItem("dataLiveDemoeFiles");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataLiveDemoeFiles", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    hitButtonPricingeFiles() {
        var data = {};
        data["type"] = "click";
        data["button"] = "pricingeFiles";
        
        var checkItemLocalStorage = localStorage.getItem("dataPricingeFiles");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataPricingeFiles", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    render() {
        const { data } = this.props;
        const introduction = data.introduction.nodes;
        const introduction_vi = data.introduction_vi.nodes;
        const row_1 = data.row_1.nodes;
        const row_1_vi = data.row_1_vi.nodes;
        const row_2 = data.row_2.nodes;
        const row_2_vi = data.row_2_vi.nodes;
        const row_3 = data.row_3.nodes;
        const row_3_vi = data.row_3_vi.nodes;
        const row_4 = data.row_4.nodes;
        const row_4_vi = data.row_4_vi.nodes;
        const row_5 = data.row_5.nodes;
        const row_5_vi = data.row_5_vi.nodes;
        const practical_features_introduction = data.practical_features_introduction.nodes;
        const practical_features_introduction_vi = data.practical_features_introduction_vi.nodes;
        const practical_features_customer_portal = data.practical_features_customer_portal.nodes;
        const practical_features_customer_portal_vi = data.practical_features_customer_portal_vi.nodes;

        var customSettingsSupportCustomerPortal = {
            dots: false,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1367,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Layout>
                <SEO title="eFiles" description="Ticket or Issue management system for your enterprise that run on Microsoft Teams. Helpdesk system to improve customer's satisfaction and reliable tool for issue tracking." meta={[{ name: `keywords`, content: ["collaboration tools on Microsoft Teams", "helpdesk system on Microsoft Teams", "ticket management", "issue management"] }]} pathname="/products/efiles" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-efiles-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-efiles-introduction-detail">
                                <Row className="app-efiles-introduction-row-custom">
                                    <Col className="app-efiles-introduction-col-custom" xs={12} md={12} lg={5}>
                                        <div className="app-efiles-introduction-left">
                                            {introduction.map((res) => (
                                                <div className="app-data-en" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                            ))}
                                            {introduction_vi.map((res) => (
                                                <div className="app-data-vi" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={1}></Col>
                                    <Col className="app-efiles-introduction-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-efiles-introduction-right">
                                            <img className="app-efiles-introduction-img" src="/app-efiles-introduction.svg" alt="efiles introduction" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-efiles" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>A new experience with the file management 
                                        <br/>
                                        in Microsoft Teams</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-efiles-detail">
                                <Row className="app-efiles-row-custom app-efiles-row-1">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-1-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_1.map((res) => (
                                            <div className="app-data-en app-efiles-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {row_1_vi.map((res) => (
                                            <div className="app-data-vi app-efiles-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-1-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-efiles-right-side">
                                            <img 
                                                className="app-efiles-row-1-img" 
                                                src="/app-efiles-01.svg" 
                                                alt="app efiles row 1" 
                                                onClick={this.increaseImageWidthRow1} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-efiles-row-custom app-efiles-row-2">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-2-col-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-efiles-left-side">
                                            <img 
                                                className="app-efiles-row-2-img" 
                                                src="/app-efiles-02.svg" 
                                                alt="app efiles row 2" 
                                                onClick={this.increaseImageWidthRow2} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-2-col-right" xs={12} md={12} lg={12} xl={5}>
                                        {row_2.map((res) => (
                                            <div className="app-data-en app-efiles-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {row_2_vi.map((res) => (
                                            <div className="app-data-vi app-efiles-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-efiles-row-custom app-efiles-row-3">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-3-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_3.map((res) => (
                                            <div className="app-data-en app-efiles-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {row_3_vi.map((res) => (
                                            <div className="app-data-vi app-efiles-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-3-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-efiles-right-side">
                                            <img 
                                                className="app-efiles-row-3-img" 
                                                src="/app-efiles-03.svg" 
                                                alt="app efiles row 3" 
                                                onClick={this.increaseImageWidthRow3} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                                <Row className="app-efiles-row-custom app-efiles-row-4">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-4-col-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-efiles-left-side">
                                            <img 
                                                className="app-efiles-row-4-img" 
                                                src="/app-efiles-04.svg" 
                                                alt="app efiles row 4" 
                                                onClick={this.increaseImageWidthRow4} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-4-col-right" xs={12} md={12} lg={12} xl={5}>
                                        {row_4.map((res) => (
                                            <div className="app-data-en app-efiles-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {row_4_vi.map((res) => (
                                            <div className="app-data-vi app-efiles-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-efiles-row-custom app-efiles-row-5">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-5-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_5.map((res) => (
                                            <div className="app-data-en app-efiles-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {row_5_vi.map((res) => (
                                            <div className="app-data-vi app-efiles-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-efiles-col-custom app-efiles-row-5-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-efiles-right-side">
                                            <img 
                                                className="app-efiles-row-5-img" 
                                                src="/app-efiles-05.svg" 
                                                alt="app efiles row 5" 
                                                onClick={this.increaseImageWidthRow5} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-efiles-practical-features" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {practical_features_introduction.map((res) => (
                                        <div className="app-data-en app-title" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {practical_features_introduction_vi.map((res) => (
                                        <div className="app-data-vi app-title" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-efiles-practical-features-detail">
                                <div className="app-data-en app-efiles-practical-features-tabs">
                                    <Slider {...customSettingsSupportCustomerPortal}>
                                        {practical_features_customer_portal.map((res) => (
                                            <div className="app-practical-features-slider" key={res.id}>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                <div className="content">
                                                    <h5>{res.frontmatter.title}</h5>
                                                    <h6>{res.frontmatter.description}</h6>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                <div className="app-data-vi app-efiles-practical-features-tabs">
                                    <Slider {...customSettingsSupportCustomerPortal}>
                                        {practical_features_customer_portal_vi.map((res) => (
                                            <div className="app-practical-features-slider" key={res.id}>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                <div className="content">
                                                    <h5>{res.frontmatter.title}</h5>
                                                    <h6>{res.frontmatter.description}</h6>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default efiles

export const query = graphql`
    query eFilesPage {
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
		row_1: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_1" } } }
        ) {
            nodes {
                html
                id
            }
        }
		row_1_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_1_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_2: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_2" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_2_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_2_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_3: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_3" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_3_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_3_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_4: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_4" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_4_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_4_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_5: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_5" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_5_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_row_5_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_practical_features_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_practical_features_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_customer_portal: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_practical_features_customer_portal" } } }
            sort: { fields: frontmatter___keyNumber, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        practical_features_customer_portal_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "efiles_practical_features_customer_portal_vi" } } }
            sort: { fields: frontmatter___keyNumber, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
    }
`;
